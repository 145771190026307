/* eslint-disable camelcase,no-undef */
import React, { Component, Suspense } from 'react';
//import { MenuItem } from 'react-bootstrap';
import  CSVList  from "../../components/PeopleHomeV2/CSVList";
// import { OverlayTrigger } from "react-bootstrap";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { toast } from 'react-toastify';
import {
  Layout, Container, RequestContainer, RequestText, RequestCardWrapper, RequestCard, MainSectionView2,
  BottomCard, FilterIcon1, DividerLine, StyledInputV2, SearchFilter1,
  SearchIcon, BuddyTabContainer, BuddyTab1, Heading, ManageButton, ViewContainerV2,CustomDropDownV2, SearchFilterContainer1, CustomDropDownItem , DropdownTitle,
} from './styles';
import { PaginationCircleNew } from '../Journeys/styles';
import { UserButton } from '../EngagementSection/styles';
//import PeopleGridView from '../PeopleGridViewV2/communityGrid';
import Waiting from '../Waiting';
import {
  RemoveCSVData, FetchEmployeeCount, DeleteEmployeeAPI, FetchSetRemoveAPI, PostAddEmployee,
  fetchSearchPeople, getListOfEmployees, getSortedEmployees, uploadBiometricForm, viewPhysicianFormAPI, getUserRoles,
  getFriendRequests, acceptOrRejectFriendRequest, getWellnessInterests,
} from '../../redux/actions';
import { getPermissionStatus } from '../../utils/methods';
import { withTranslation } from 'react-i18next';
import { ImageUrl } from "../../utils/constants";
import { editDepartmentApi, editLocationApi, getCompanyDetails, sendFriendRequest } from '../../redux/actions/peopleHomeActions';
//import { peoplePagePoints } from '../../../mockData.json'';
import { ContainerV2, FieldTitleV2, StyledBodyV2, StyledHeader, StyledmodalV2 } from '../NewEmployeePopupV2/styles';
import { BASE_URL } from "../../utils/config";
import InactiveUsersListView from '../PeopleListViewV2/inactiveUsersListView';
import { getNotRegisteredUsersList,getDepartmentDropdownData, getLocationDropdownData } from '../../redux/constants/apiConstants';
import HoverButton from '../common/HoverButton';
import { AddEmployeeIcon,EmployeeCountIcon,SearchButtonIcon, UpdateDepartmentIcon,UpdateLocationIcon,PaginationIcon, DropDownIcon, PrintNewIcon } from '../../utils/icons';
import NewEmployeePopup from '../NewEmployeePopupV2';
import AddLocationPopup from '../NewEmployeePopupV2/locationPopup';
import AddDepartmentPopup from '../NewEmployeePopupV2/departmentPopup';
import { Row } from '../PeopleListView/styles';
// import inactiveUsersListView from '../PeopleListViewV2/inactiveUsersListView';
// import inactiveUsersListView from '../PeopleListViewV2/inactiveUsersListView';
const WAIT_INTERVAL = 1500;
const ENTER_KEY = 13;
const headers = [
  { label: "Name", key: "name" },
  { label: "Email", key: "user_email" },
];



class PeopleInactiveUsers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedEmployee: null,
      departmentId: null,
      engagement: '',
      alphabetical: null,
      alphabeticalId: '',
      department: '',
      location:'',
      search: '',
      showPopUp: false,
      showGrid: props.showView,
      companyId: props.companyInfo['id'],
      engagementSort: false,
      showSort: false,
      pageNo: 1,
      loading: false,
      minLimit: 1,
      maxLimit: 6,
      total: '',
      countingValues: '',
      locationDropDown: null,
      locationDropDownId: '',
      loader: false,
      showPDFModal: false,
      buttonText: '',
      pdfBase64: '',
      uploadUid: null,
      locationData:[],
      departmentData:[],
      showDeleteUserPopUp: false,
      selectUserDropDown: 'All',
      selectUserDropDownId: 0,
      showRolesPopup: false,
      showUserPopUp: false,
      wellnessInterest: null,
      wellnessInterestId: '',
      userId: null,
      initiativeId: null,
      initiativeName: null,
      wellnessSearch: false,
      peoplePage: null,
      peoplePageId: '',
      showLocation: false,
      showDepartment: false,
      participantLocation: false,
      participantDepartment: false,
      activeButton: false,
      selectedParticipant: [],
      showNote:false,
      NoteText:"Location",
      showTeams: false,
      inactiveUsersList: [],
      inactiveUserCount:0,
      pageCount:0,
      remainingInactiveUser: 0,
      enableShowAll:'',
      selectAll: 0,
      isShowLocation: null,
      isShowDepartment: null,
      daysLeft: false, 
      departmentName: '',
      locationName: ''
    };
    this.timer = null
  }

  onEnterMouse = (uid, id, name) => {
    this.setState({
      userId: uid,
      initiativeId: id,
      initiativeName: name
    })
  };

  onLeaveMouse = () => {
    this.setState({
      userId: null,
      initiativeId: null
    })
  };

  // UNSAFE_componentWillReceiveProps(prevProps) {
  //   const { inactiveUserCount } = this.state;
  //   console.log("testing 1",inactiveUserCount, this.state.countingValues, prevProps)
  //   if (inactiveUserCount && (this.state.countingValues !== inactiveUserCount)) {
  //     console.log("testing 2",inactiveUserCount, this.state.countingValues)
  //     this.setState({
  //       total: Math.ceil(inactiveUserCount / 20),
  //       countingValues: inactiveUserCount
  //     }, () => {
  //       if (this.state.total < 6) {
  //         this.setState({
  //           maxLimit: this.state.total
  //         })
  //       }
  //       else {
  //         this.setState({
  //           maxLimit: 6
  //         })
  //       }
  //     })
  //   }
  // }

  getPageCount= (inactiveUserCount)=> {
    if (inactiveUserCount && (this.state.countingValues !== inactiveUserCount)) {
      this.setState({
        total: Math.ceil(inactiveUserCount / 20),
        countingValues: inactiveUserCount
      }, () => {
        if (this.state.total < 6) {
          this.setState({
            maxLimit: this.state.total
          })
        }
        else {
          this.setState({
            maxLimit: 6
          })
        }
      })
    }
  }


  updateParentState = (state) => {
    this.setState(state);
  };

  nextPageDetail = (e) => {
    e.preventDefault();
    const bottom = e.target.scrollHeight - Math.round(e.target.scrollTop) === e.target.clientHeight;
    if (bottom) {
      this.setState({
        pageNo: this.state.pageNo + 1
      }, () => {
        if (this.state.inactiveUserCount > this.state.inactiveUsersList.length) {
          this.fetchInactiveUsersList();
        }
      })
    }
  };

  fetchDepartmentData = async () => {
    const AuthToken = localStorage.getItem("AUTH_TOKEN");
    let url = BASE_URL + getDepartmentDropdownData + "/" + this.state.companyId ;
    await fetch(url, { method: "GET", headers: { AUTHTOKEN: AuthToken } })
      .then((res) => {
        res
          .json()
          .then((data) => {
            // window.console.log("api data",data)
            if(data.status === 'success'){
              let department = [{ department: 'All Departments' }];
              this.setState({departmentData: department.concat(data.data) }
              );
            }
            
            
          })
          .catch((error) => {
            toast.error(error.message);
          });
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  fetchLocationData = async () => {
    const AuthToken = localStorage.getItem("AUTH_TOKEN");
    let url = BASE_URL + getLocationDropdownData + "/" + this.state.companyId ;
    await fetch(url, { method: "GET", headers: { AUTHTOKEN: AuthToken } })
      .then((res) => {
        res
          .json()
          .then((data) => {
            // window.console.log("api data",data)
            if(data.status === 'success'){
              let location = [{ location: 'All Locations' }];
              this.setState({locationData: location.concat(data.data) }
              ); 
            }
            
            
          })
          .catch((error) => {
            toast.error(error.message);
          });
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };


  // fetchInactiveUsersList = async () => {
  //   const AuthToken = localStorage.getItem("AUTH_TOKEN");
  //   let payload = {
  //     company_id: this.state.companyId,
  //     search_key: this.state.search,
  //     limit: 20,
  //     page_no : this.state.pageNo,
  //     department: this.state.department,
  //     location:this.state.location,

  //   };
  //   const { selectAll, selectedParticipant } = this.state;
  //   let url = BASE_URL + getNotRegisteredUsersList;
  //   await fetch(url ,{ method: "POST",
  //     headers: {
  //       AUTHTOKEN: AuthToken,
  //       "Content-Type": "application/json",
  //       Accept: "application/json",
  //     },
  //     body:JSON.stringify(payload) })
  //     .then((res) => {
  //       res
  //         .json()
  //         .then((data) => {
  //           window.console.log("here is the props",data,selectAll)
  //           if (selectAll === 1) {
  //             data.search_data.forEach((employee) => {
  //               window.console.log("daysleft are",employee.days.left)
  //               if (!employee.days_left && !selectedParticipant.includes(employee.id)) {
  //                 selectedParticipant.push(employee.id);
  //               }
  //             });
  //           }
 
  //           // this.setState({   inactiveUsersList: [...this.state.inactiveUsersList, ...data ] ,selectedParticipant, inactiveUserCount:data.data.count, remainingInactiveUser:data.data.remaining_to_sent_count });
  //           this.setState({ inactiveUsersList: data.data.search_data,inactiveUserCount:data.data.count,selectedParticipant, remainingInactiveUser:data.data.remaining_to_sent_count });
  //           this.getPageCount(data.data.count);
  //         })
  //         .catch((error) => {
  //           toast.error(error.message);
  //         });
  //     })
  //     .catch((error) => {
  //       toast.error(error.message);
  //     });
  // };




  fetchInactiveUsersList = async (limit) => {
    const AuthToken = localStorage.getItem("AUTH_TOKEN");
    let payload = {
      company_id: this.state.companyId,
      search_key: this.state.search,
      limit: limit ? limit : 20,
      page_no: this.state.pageNo,
      department: this.state.department,
      location: this.state.location,
    };
  
    const { selectAll, selectedParticipant } = this.state;
    let url = BASE_URL + getNotRegisteredUsersList;
  
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          AUTHTOKEN: AuthToken,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(payload),
      });
  
      const data = await response.json();
  
      if (data.status ==='success') {
        // window.console.log("here is the props", data);
  
        if (selectAll === 1 && Array.isArray(data.data.search_data)) {
          data.data.search_data.forEach((employee) => {
            if (!employee.days_left && !selectedParticipant.includes(employee.id)) {
              selectedParticipant.push(employee.id);
            }
          });
        }
         
        const daysLeft = data.data.search_data.every((employee) => employee.days_left !== 0);
        // window.console.log("value is", daysLeft)

        this.setState({
          inactiveUsersList: data.data.search_data,
          selectedParticipant,
          inactiveUserCount: data.data.count,
          remainingInactiveUser: data.data.remaining_to_sent_count,
          isShowLocation: data.data.location_seen_status,
          isShowDepartment: data.data.department_seen_status,
          daysLeft: daysLeft,
        });
  
        this.getPageCount(data.data.count);
      } else {
        toast.error(data.message || "Something went wrong");
      }
    } catch (error) {
      toast.error(error.message);
    }
  };
  



  fetchInactiveSearchUsersList = async (obj) => {
    const AuthToken = localStorage.getItem("AUTH_TOKEN");
    let url = BASE_URL + getNotRegisteredUsersList;
    await fetch(url ,{ method: "POST",
      headers: {
        AUTHTOKEN: AuthToken,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body:JSON.stringify(obj) })
      .then((res) => {
        res
          .json()
          .then((data) => {
            this.setState({ inactiveUsersList: data.data.search_data, inactiveUserCount: data.data.count, remainingInactiveUser: data.data.remaining_to_sent_count  });
          })
          .catch((error) => {
            toast.error(error.message);
          });
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  componentDidMount() {
    this.props.getCompanyDetails(this.state.companyId);
    this.fetchLocationData();
    this.fetchDepartmentData();
    this.fetchInactiveUsersList();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.companyId !== this.state.companyId) {
      this.setState({
        search: '',
        pageNo: 1,
        engagementSort: false,
        showSort: false,
        department: null,
        departmentId: null,
        alphabetical: null,
        alphabeticalId: '',
      }, () => {
        this.fetchInactiveUsersList();
      })
    }
  }

  changeOrder = () => {
    this.setState({
      engagementSort: !this.state.engagementSort,
      showSort: true,
      pageNo: 1,
      selectedEmployee: null,
      alphabetical: null,

    }, () => {
      this.fetchInactiveUsersList();
    })
  };

  setorRemoveAction = (uid, status) => {
    const { fetchSetRemoveAPI } = this.props;
    let obj = {};
    obj['uid'] = uid;
    obj['status'] = !status ? 1 : 0;
    obj['company_id'] = this.state.companyId;
    obj['role'] = 11;
    fetchSetRemoveAPI(obj);
  };

  onSearchFunction = () => {
    clearTimeout(this.timer);
    let obj = {};
    obj['search_key'] = this.state.search;
    obj['limit'] = "20";
    obj['company_id'] = this.state.companyId;
    obj['page_no'] = this.state.pageNo;
    obj['location'] = this.state.location;
    obj['department'] = this.state.department;
    this.timer = setTimeout(() => {
      this.fetchInactiveSearchUsersList(obj);
    }, WAIT_INTERVAL)
  };

  handleKeyDown = (evt) => {
    let obj = {};
    obj['search_key'] = this.state.search;
    obj['limit'] = "20";
    obj['company_id'] = this.state.companyId;
    obj['page_no'] = this.state.pageNo;
    obj['location'] = this.state.location;
    obj['department'] = this.state.department;
    if (evt.keyCode === ENTER_KEY) {
      clearTimeout(this.timer);
      this.fetchInactiveSearchUsersList(obj);
    }
  };

  changeLoading = () => {
    this.setState({
      loading: false
    })
  };

  fetchEmployeePost = (uid) => {
    this.setState({
      uploadUid: null
    });
    let obj = {};
    const { pageNo, companyId, search, engagementSort, showSort, alphabeticalId,
      departmentId, department, locationDropDown, locationDropDownId, selectUserDropDownId } = this.state;
    const { fetchEmployeeCount, sendFriendRequest } = this.props;
    obj['company_id'] = companyId;
    obj['name'] = search && search.trim();
    obj['page_no'] = pageNo;
    obj['limit'] = 20;
    if (showSort) {
      if (engagementSort) {
        obj['engagement_sort'] = 'DESC';
      }
      else {
        obj['engagement_sort'] = 'ASC';
      }
    }
    else {
      if (alphabeticalId === 1) {
        obj['name_sort'] = 'ASC';
      }
      else if (alphabeticalId === 2) {
        obj['name_sort'] = 'DESC';
      }
    }
    if (selectUserDropDownId === 0) {
      obj['user_type'] = 'all'
    }
    if (selectUserDropDownId === 1) {
      obj['user_type'] = 0
    }
    if (selectUserDropDownId === 2) {
      obj['user_type'] = 1
    }
    if (selectUserDropDownId === 3) {
      obj['user_type'] = 2
    }
    if (departmentId && department !== 'All Department') {
      obj['department_id'] = departmentId;
    }

    if (locationDropDownId && locationDropDown !== 'All Location') {
      obj['city_state_id'] = locationDropDownId;
    }
    if (pageNo === 1 && !showSort && search === '' && !departmentId && !alphabeticalId && !locationDropDownId) {
      fetchEmployeeCount(obj, false);
    } else {
      fetchEmployeeCount(obj, false);
    }
    sendFriendRequest(uid, obj);
  };


  deleteUserAction = (uid) => {
    const { deleteEmployeeAPI } = this.props;
    let obj = {};
    obj['user_uid'] = uid;
    deleteEmployeeAPI(obj);
    this.setState({
      selectedEmployee: null
    })
  };

  getCompany = (value) => {
    if (!this.state.loading) {
      const { companies, setCompany } = this.props;
      const id = companies.filter((company) => company.company_name === value)[0].id;
      this.setState({
        companyId: id,
        pageNo: 1,
        selectedEmployee: null,
        countingValues: '',
        minLimit: 1,
        maxLimit: '',
        departmentId: null,
        locationDropDownId: null,
      });
      setCompany(id, value)
    }
  };

  addEmployeePopUp = () => {
    this.setState({
      showPopUp: !this.state.showPopUp
    });
  };

  updateLocationPopUp = () => {
    this.setState({
      showLocation: !this.state.showLocation
    });
  };

  addParticipantsLocation = () => {
    let participant = JSON.parse(localStorage.getItem("selectedEmployeeList"))
    this.setState({
      participantLocation: !this.state.participantLocation,
      selectedParticipant: participant,
      activeButton: ! this.state.activeButton 
    })
  }

  addParticipantsDepartment = () => {
    let participant = JSON.parse(localStorage.getItem("selectedEmployeeList"))
    this.setState({
      participantDepartment: !this.state.participantDepartment,
      selectedParticipant: participant
    })
  }

  updateDepartmentPopUp = () => {
    this.setState({
      showDepartment: !this.state.showDepartment
    });
  };

  userModal = () => {
    this.setState((prevState) => ({
      showUserPopUp: !prevState.showUserPopUp
    }));
  };

  showEmployeeDetails = (id) => {
    if (!this.state.loading) {
      this.setState({
        selectedEmployee: id
      })
    }
  };

  onSelect = (value, state) => {
    this.setState({
      [state]: value
    }, () => {
      const { department, engagement, alphabetical, search } = this.state;
      this.sortEmployees(search, engagement, department, alphabetical);
    });
  };

  onChange = (e) => {
    e.preventDefault();
    if (!this.state.loading) {
      this.setState({
        search: e.target.value,
        pageNo: 1,
        engagementSort: false,
        showSort: false
      }, () => {
        this.onSearchFunction();
        // const { department, engagement, alphabetical, search } = this.state;
        // this.sortEmployees(search, engagement, department, alphabetical);
      });
    }
  };

  sortEmployees = (search, engagement, department, alphabetical) => {
    this.props.fetchSortedEmployees(search, engagement, department, alphabetical);
  };

  showView = (value) => {
    this.setState({
      showGrid: value
    })
  };

  getMenuItems = () => {
    const { companies } = this.props;
    return companies && companies.map((company) => company.company_name);
  };

  getPaginationCircle = () => {
    let circleButton = [];
    for (let i = this.state.minLimit; i <= this.state.maxLimit; i++) {
      circleButton.push(<PaginationCircleNew key={i} active={i == this.state.pageNo} onClick={() => this.setPagination(i)}><div>{i}</div></PaginationCircleNew>)
    }
    return circleButton;
  };

  setPagination = (activeIndex) => {
    if (activeIndex !== this.state.pageNo) {
      if (this.state.total <= 6) {
        this.setState({
          minLimit: 1,
          maxLimit: this.state.total,
          pageNo: activeIndex
        }, () => {
          this.fetchInactiveUsersList();
        });
      }
      else {
        if (activeIndex === 1) {
          this.setState({
            pageNo: activeIndex,
            maxLimit: 6,
            minLimit: 1
          }, () => {
            this.fetchInactiveUsersList();
          })
        }
        else {
          if (activeIndex + 3 > this.state.total) {
            this.setState({
              pageNo: activeIndex,
              maxLimit: this.state.total,
              minLimit: this.state.total - 4
            }, () => {
              this.fetchInactiveUsersList();
            });
          }
          else {
            this.setState({
              pageNo: activeIndex,
              maxLimit: activeIndex + 3,
              minLimit: activeIndex - 1
            }, () => {
              this.fetchInactiveUsersList();
            });
          }
        }
      }
    }
  };

  changeLoader = (bool) => {
    this.setState({
      loader: bool
    })
  };



  removePDF = () => {
    this.setState({
      showPDFModal: false,
      pdfBase64: '',
      buttonText: "",
      uploadUid: null
    })
    document.getElementById('biometric-user-admin-file').value = '';
  }

  showPDFData = (id) => {
    const { viewPhysicianFormAPI } = this.props;
    this.changeLoader(true);
    viewPhysicianFormAPI(id, this.changeLoader)

  };

  onToggleDeletePopup = () => {
    this.setState((prev) => ({ showDeleteUserPopUp: !prev.showDeleteUserPopUp }));
  };
  onToggleRolesPopup = (uid) => {
    this.setState((prev) => ({ showRolesPopup: !prev.showRolesPopup }), () => { this.state.showRolesPopup && this.props.getUserRoles(uid) });
  };

  acceptOrRejectRequest = (id, status) => {
    const { acceptOrRejectFriendRequest } = this.props;
    acceptOrRejectFriendRequest({ request_id: id, status: status });
  };

  fallBack = (e) => {
    e.target.src = '/public/images/default.png'
  }

  showManage = () => {
    const { history } = this.props;
    history.push('/community/manage-buddies')
  }

  showInternalText = () => (
    (<Container>
      <FieldTitleV2 paddingTop={1}>All The Employee added to your selected {this.state.NoteText}.
      </FieldTitleV2>
    </Container>)
  )

  notePopup = () => (
    (<StyledmodalV2 bulkWidth left show={this.state.showNote}>     
      <StyledHeader closeButton>
        <ContainerV2 maxWidth right
          bulkWidth>
          <div className="headerName">
            Note
          </div>
          <div className="cross_img">
            <img src="/public/images/new_close_icon.png" alt="" style={{ "cursor": "pointer" }} onClick={()=>{this.handleShowNote('')}} />
          </div>
        </ContainerV2>
      </StyledHeader>
      <StyledBodyV2>
        {this.showInternalText()}
      </StyledBodyV2>
    </StyledmodalV2>)
  )

  updateDropdown = (name, index, departmentName) => {
    // window.console.log("testing data",name, index, departmentName);
    this.setState({
      department: name=="All Departments"?"":name,
      departmentId: index,
      departmentName: departmentName,
      pageNo: 1
    },this.fetchInactiveUsersList);
  };


  updateLocation = (name, index, locationName) => {
    this.setState({
      location: name=="All Locations"?"":name,
      locationId: index, 
      locationName:locationName,
      pageNo: 1
    },this.fetchInactiveUsersList);
  };
  

  handleShowNote = (text) => {
    this.setState({
      showNote: !this.state.showNote,
      NoteText:text
    })
    this.fetchInactiveUsersList();
  }

  hideShowTeam = (status) => {
    const { history } = this.props;
    this.setState({ showTeams: status })
    if(status){
      history.push('/company/people/team')
    }
  }
  hideShowEmployee = (status) => {
    const { history } = this.props;
    this.setState({ showTeams: status });
    if (status) {
      history.push("/company/people");
    }
  };

  printAction = async (bool) => {
    await this.fetchInactiveUsersList(this.state.inactiveUserCount);
    this.setState({
      printAttendees: bool
    });
  }

  render() {
    const { employeeData, searchAmigo, companyDetails, friendRequests, history,role, t,userCompany } = this.props;
    const { selectedEmployee, loader, userId, initiativeId, initiativeName,  participantLocation, activeButton, showTeams,inactiveUserCount,printAttendees,showLocation, showDepartment, showPopUp, departmentData,locationData, remainingInactiveUser } = this.state;
    let selectedEmp = false;
    if (!(_.isUndefined(employeeData)) && !(_.isNull(employeeData)) && employeeData.length > 0) {
      selectedEmp = selectedEmployee ? selectedEmployee : employeeData[0].uid;
    }
    else {
      selectedEmp = false;
    }


    
    return (
      <Layout>
        {loader && <Waiting />}
        <Container>
          {this.state.loading && <Waiting />}

          {
            <BuddyTabContainer show={activeButton} style={{display: 'flex', justifyContent: 'space-between'}}>
              <div style={{width: '50%', display: 'flex', justifyContent: 'flex-start'}}>
                <BuddyTab1 active={showTeams ? true : false} onClick={() => this.hideShowEmployee(true)} style={{width:"auto",padding:"0 15px"}}>{searchAmigo ? t("Buddies") : t("Active")}</BuddyTab1>
                {!searchAmigo && (role==="ADMIN"|| role === "WELLNESS_ADMIN" || role === "WELLNESS_CHAMPION" || userCompany)&&<BuddyTab1 active={showTeams ? false : true} style={{width:"auto",padding:"0 15px"}}> {t("Inactive")}</BuddyTab1>}
                {!searchAmigo && ((role==="ADMIN" || role === "WELLNESS_ADMIN" || role === "WELLNESS_CHAMPION") || userCompany && userCompany.showTeam)?<BuddyTab1 active={showTeams ? true : false} onClick={() => this.hideShowTeam(true)}> {t("Teams")}</BuddyTab1>:null}
              </div>
              <div style={{width: '50%', display: 'flex', justifyContent: 'flex-end'}}>
                <ViewContainerV2>
                  {!searchAmigo && this.props.departmentDetails && this.props.departmentDetails != "" && this.props.departmentDetails != null && this.props.departmentDetails.length > 0 &&
                  <HoverButton onClick={this.updateDepartmentPopUp} title={"Manage Departments"} svgPath={UpdateDepartmentIcon()} activeColor={"#007AB1"} fillOpacity={"1"} />
                  }
                  {!searchAmigo && this.props.locationDetails && this.props.locationDetails.length > 0 && 
                  <HoverButton onClick={this.updateLocationPopUp} title={"Manage Locations"} svgPath={UpdateLocationIcon()} fillOpacity={1} activeColor={"#007AB1"}/>
                  }
                  {!searchAmigo && (getPermissionStatus("Add Employee", this.props.userPermissions)) &&
                  <div style={{marginRight:"10px"}}><HoverButton onClick={this.addEmployeePopUp} title={"Add Employees"} svgPath={AddEmployeeIcon()} fillOpacity={"1"} activeColor={"#007AB1"}/></div>
                  }
                  <div style={{marginRight:"10px",cursor:'pointer'}}><HoverButton  marginLeft={'0px'}  title={"Download"} onClick={() => this.printAction(true)} fillOpacity={1}  disabled={false} backgroundColor={"#f0f6f8"} backgroundColorActive={"#005c87"} activeColor={"#007AB1"} inActiveColor={"white"} svgPath={<PrintNewIcon/>}/></div>       
                </ViewContainerV2>
              </div>
              {/* {!searchAmigo && <div style={{width: '50%', display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
                <div className='radio-btn' onClick={  
                  this.addParticipantsLocation} >
                  <div className='innerCircle'></div>
                </div>
                <div className='bulk-bttn'>
                  Bulk Selection
                </div>
              </div>} */}

              {searchAmigo && <ManageButton width="125px" height="40px" onClick={() => this.showManage()}>{t("Manage")}</ManageButton>}
            </BuddyTabContainer>
          }

          <FilterIcon1>
            <SearchFilter1>
              <StyledInputV2
                type="text" value={this.state.search} placeholder={t("Search users, teams, etc")}
                onChange={(e) => this.onChange(e)}
                onKeyDown={this.handleKeyDown}
              />
              <SearchIcon> {SearchButtonIcon()}</SearchIcon>
            </SearchFilter1>
            <div className="wrapper">
              {
                (departmentData && departmentData.length > 1 && departmentData[0].department !== null) || (locationData && locationData.length > 1 && locationData[0].location !== null ) ? (
                
                  <SearchFilterContainer1 
                    inactiveUser={true} 
                    scroll={this.state.departmentData && !_.isNull(this.state.departmentData) && this.state.departmentData.length > 15 ? 1 : 0}
                    hasDepartmentData={departmentData && departmentData.length > 0 && departmentData.some((item) => item !== null)}
                    hasLocationData={locationData && locationData.length > 0}
                  >
                    <div className="filterTitle">Filters :</div>
                    
                    {departmentData && departmentData.length > 1  && departmentData[0].department !== null ? (
                      <div className="community-department" style={{marginRight:'10px'}}>
                        {(this.state.selectUserDropDownId == 0 || this.state.selectUserDropDownId == 3 || this.state.selectUserDropDownId == 2 || this.state.selectUserDropDownId == 1) && this.state.departmentData && this.state.departmentData.length ?
                          (<CustomDropDownV2 inputpadding="0px" 
                            type="button" title={ <DropdownTitle> <div className="title">{t(this.state.department)||t("All Departments")}</div><div style={{marginLeft:'5px'}} className="arrow-icon" > {DropDownIcon()}</div></DropdownTitle>}
                            id="department-sort"
                            onSelect={
                              (index) =>
                                this.updateDropdown(departmentData[index].department,index, departmentData[index].department)
                            }
                          >
                            {departmentData.map((list, index) => (
                              <CustomDropDownItem key={index} eventKey={index}>
                                {t(list.department)}
                              </CustomDropDownItem>
                            ))}
                          </CustomDropDownV2> ): null}
                      </div>) : null 
                    }
              
                    {locationData && locationData.length > 1  && locationData[0].location !== null ? (
                      <div className="community-department">
                        {(this.state.selectUserDropDownId == 0 || this.state.selectUserDropDownId == 3 || this.state.selectUserDropDownId == 1 || this.state.selectUserDropDownId == 2) && this.state.locationData && this.state.locationData.length  ?
                          (<CustomDropDownV2 inputdisplay="block" inputpadding="0px"
                            title={<DropdownTitle>
                              <div className="title">{t(this.state.location) || t('All Locations')}</div>
                              <div style={{marginLeft:'10px'}} className="arrow-icon" > {DropDownIcon()}</div>
                            </DropdownTitle>}
                            id="role-sort"
                            onSelect={
                              (index) => 
                                this.updateLocation(locationData[index].location, index, locationData[index].location)
                            }
                          >
                            {                        
                              locationData.map((list, index) => (
                                <CustomDropDownItem key={index} eventKey={index}>{t(list.location)}</CustomDropDownItem>    
                              ))
                            }
                          </CustomDropDownV2>) : null}
                      </div>) : null 
                    }  
                                            
                  </SearchFilterContainer1>   ) : null}
            </div>
                
         

          </FilterIcon1>
          {
            <BottomCard widthChange={true}>
              <div>
                <Suspense fallback={<Waiting/>}>
                  <div className="profile" >
                    {EmployeeCountIcon()}
                  </div>
                </Suspense>
                <div className="count">
                  {inactiveUserCount || 0} {t("Inactive Users")}
                </div>
              </div>
              <DividerLine/>
            </BottomCard>
          }
            
          {friendRequests && friendRequests.length > 0 && searchAmigo && <RequestContainer background="white">
            <RequestText>
              {t("Buddy Requests Received")}
            </RequestText>
            <RequestCardWrapper>
              {friendRequests && friendRequests.length > 0 && friendRequests.map((request, index) =>
                (<RequestCard key={index}>
                  <div className="profile">
                    <img onError={this.fallBack} src={`${ImageUrl}/${request.profile_image}`} alt={"Profile"} />
                  </div>
                  <div className="info">
                    <div className="name">{t(request.fullname)}</div>
                    <div className="wellness-icon">
                      {request.wellness_interest && request.wellness_interest.length > 0 && request.wellness_interest.map((wellness, index) =>
                        (index < 5 && <div key={index} onMouseEnter={() => this.onEnterMouse(request.id, wellness.interest_id, wellness.interest_name)} onMouseLeave={() => this.onLeaveMouse()}>
                          <img src={`${ImageUrl}/${wellness.interest_icon}`} />
                          {userId === request.id && initiativeId === wellness.interest_id &&
                          <div className="hover-card">
                            <div> {t(initiativeName)}</div>
                          </div>}
                        </div>
                        ))}
                    </div>
                  </div>
                  <div className="last-card">
                    <div className="confirm" onClick={() => this.acceptOrRejectRequest(request.id, 'Accepted')}>{t("Confirm")}</div>
                    <div className="cancel" onClick={() => this.acceptOrRejectRequest(request.id, 'Rejected')}>{t("Cancel")}</div>
                  </div>
                </RequestCard>))}
            </RequestCardWrapper>
          </RequestContainer>}
          {searchAmigo && <Heading>
            {t("Connect with colleagues who share similar roles or wellness interests")}
          </Heading>}
          {
            inactiveUserCount>0?<div style={{ "width": "100%", "display": "flex" }}>
              <MainSectionView2 style={{marginTop:'10px'}}>
                <InactiveUsersListView
                  departmentData={departmentData}
                  fetchInactiveUsersList={this.fetchInactiveUsersList}
                  updateParentState={this.updateParentState}
                  locationData={locationData}
                  inactiveUsersList={this.state.inactiveUsersList}
                  showEmployeeDetails={this.showEmployeeDetails}
                  selectedEmployee={selectedEmp}
                  changeOrder={this.changeOrder}
                  searchAmigo={searchAmigo}
                  sendFriendRequestPost={this.fetchEmployeePost}
                  history={history}
                  show={activeButton}
                  showAll = {this.state.enableShowAll}
                  inactiveUserCount={inactiveUserCount}
                  remainingInactiveUser={remainingInactiveUser}
                  isShowLocation={this.state.isShowLocation}
                  isShowDepartment={this.state.isShowDepartment}
                  daysLeft={this.state.daysLeft}
                  plp={{"companyId":this.state.companyId, "fetchEditLocationApi":this.props.fetchEditLocationApi,"show":participantLocation, "data":this.props.locationDetails, "departmentData":this.props.departmentDetails, "showModalPopUp":this.addParticipantsLocation,"companyAuthDetails":companyDetails,"handleShowNote": this.handleShowNote
                  }}
                  searchText={this.state.search}
                  locationText={this.state.location}
                  departmentText={this.state.department}

                />
                  
                {inactiveUserCount > 20 &&
                  <div className="paginationInactivePage">
                    {this.state.pageNo > 1 &&
                      <UserButton
                        width="32px"
                        height="32px"
                        Align={1}
                        float={1}
                        padding="4px"
                        fontSize="14px"
                        bgColor="rgba(0, 122, 177, 0.05)"
                        capitalize={1}
                        onClick={() => this.setPagination(this.state.pageNo - 1)}
                      >
                        <div style={{transform:"rotate(180deg)",marginLeft:"20px"}}> {PaginationIcon()}</div>
                      </UserButton>
                    }
                    {this.getPaginationCircle()}
                    {this.state.pageNo != this.state.total &&
                      <UserButton
                        width="32px"
                        height="32px"
                        Align={1}
                        padding="4px"
                        marginLeft="10px"
                        fontSize="14px"
                        bgColor="rgba(0, 122, 177, 0.05)"
                        capitalize={1}
                        onClick={() => this.setPagination(this.state.pageNo + 1)}
                      >
                        <div> {PaginationIcon()} </div>
                      </UserButton>
                    }
                  </div>
                }
              </MainSectionView2>
            </div>:
              <Row disableCursor={1} align={1} upperBoxShadow="2px solid rgba(0,0,0,0.08)">
                No Employee
              </Row>
          }
          <div>
            {printAttendees &&
                <CSVList Title={`Inactive-Employees`} removeCSVData={this.printAction} data={this.state.inactiveUsersList} headers={headers} />
            }
          </div>
        </Container>

        {!searchAmigo &&
          <NewEmployeePopup  companyId={this.state.companyId} postAddEmployee={this.props.postAddEmployee}
            show={showPopUp} showModalPopUp={this.addEmployeePopUp} companyAuthDetails={companyDetails} />
        }
        { 
          !searchAmigo && showLocation && <Suspense fallback={<Waiting/>}>
            <AddLocationPopup companyId={this.state.companyId} fetchEditLocationApi={this.props.fetchEditLocationApi}
              show={showLocation} data={this.props.locationDetails} showModalPopUp={this.updateLocationPopUp} companyAuthDetails={companyDetails} />
          </Suspense>  
        }
        {
          !searchAmigo && showDepartment && <Suspense fallback={<Waiting/>}>
            <AddDepartmentPopup companyId={this.state.companyId} fetchEditDepartmentApi={this.props.fetchEditDepartmentApi}
              show={showDepartment} data={this.props.departmentDetails} showModalPopUp={this.updateDepartmentPopUp} companyAuthDetails={companyDetails} />
          </Suspense>
        }
        
      </Layout>
    )
  }
}

PeopleInactiveUsers.defaultProps = {
  companyInfo: { id: 1, name: '' }
};

PeopleInactiveUsers.propTypes = {
  fetchListOfEmployees: PropTypes.func.isRequired,
  employeeList: PropTypes.array,
  fetchSortedEmployees: PropTypes.func.isRequired,
  sortedList: PropTypes.array,
  history: PropTypes.object,
  employeeData: PropTypes.array,
  count: PropTypes.number,
  companyInfo: PropTypes.object,
  companies: PropTypes.array,
  fetchSearchPeople: PropTypes.func,
  postAddEmployee: PropTypes.func,
  fetchSetRemoveAPI: PropTypes.func,
  deleteEmployeeAPI: PropTypes.func,
  fetchEmployeeCount: PropTypes.func,
  setCompany: PropTypes.func,
  downloadCSV: PropTypes.bool,
  csvData: PropTypes.array,
  removeCSVData: PropTypes.func,
  locationDetails: PropTypes.array,
  departmentDetails: PropTypes.array,
  paginationCount: PropTypes.number,
  searchAmigo: PropTypes.bool,
  uploadBiometricForm: PropTypes.func,
  role: PropTypes.string,
  viewPhysicianFormAPI: PropTypes.func,
  biometricPdfFile: PropTypes.string,
  userPermissions: PropTypes.array,
  userRolesList: PropTypes.array,
  getUserRoles: PropTypes.func,
  companyDetails: PropTypes.func,
  t: PropTypes.func,
  sendFriendRequest: PropTypes.func,
  fetchFriendRequests: PropTypes.func.isRequired,
  friendRequests: PropTypes.array,
  acceptOrRejectFriendRequest: PropTypes.func.isRequired,
  getWellnessInterests: PropTypes.func,
  wellnessInterests: PropTypes.array,
  showView: PropTypes.bool,
  fetchEditLocationApi: PropTypes.func,
  fetchEditDepartmentApi: PropTypes.func,
  userCompany: PropTypes.object,
  inactiveUserCount: PropTypes.number,
  getCompanyDetails:PropTypes.func,
};

const mapStateToProps = (state) => ({
  employeeList: state.peopleHome.employeeList,
  sortedList: state.peopleHome.sortedList,
  employeeData: state.peopleHome.employeeData,
  count: state.peopleHome.count,
  downloadCSV: state.peopleHome.csvMake,
  csvData: state.peopleHome.csvData,
  locationDetails: state.companyDashboard.companyPeopleLocation,
  departmentDetails: state.companyDashboard.companyPeopleDepartment,
  paginationCount: state.peopleHome.paginationCount,
  biometricPdfFile: state.profileData.biometricPdfFile,
  userPermissions: state.profileData.userPermissions,
  userRolesList: state.peopleHome.userRolesList,
  companyDetails: state.peopleHome.companyDetails,
  sentRequest: state.social.sentRequest,
  friendRequests: state.social.friendRequests,
  wellnessInterests: state.register.wellnessInterests,
  userCompany: state.profileData.userCompany
});

const mapDispatchToProps = (dispatch) => ({
  fetchListOfEmployees: () => dispatch(getListOfEmployees()),
  fetchSortedEmployees: (search, engagement, department, alphabetical) => dispatch(getSortedEmployees(search, engagement, department, alphabetical)),
  fetchSearchPeople: (data, cb) => dispatch(fetchSearchPeople(data, cb)),
  postAddEmployee: (data) => dispatch(PostAddEmployee(data)),
  fetchSetRemoveAPI: (obj) => dispatch(FetchSetRemoveAPI(obj)),
  deleteEmployeeAPI: (obj) => dispatch(DeleteEmployeeAPI(obj)),
  fetchEmployeeCount: (obj, bool) => dispatch(FetchEmployeeCount(obj, bool)),
  removeCSVData: () => dispatch(RemoveCSVData()),
  uploadBiometricForm: (obj, cb, userId, fetchEmployee) => dispatch(uploadBiometricForm(obj, cb, userId, fetchEmployee)),
  viewPhysicianFormAPI: (uid, cb) => dispatch(viewPhysicianFormAPI(uid, cb)),
  getUserRoles: (uid) => dispatch(getUserRoles(uid)),
  getCompanyDetails: (companyId) => dispatch(getCompanyDetails(companyId)),
  sendFriendRequest: (data, obj) => dispatch(sendFriendRequest(data, obj)),
  fetchFriendRequests: () => dispatch(getFriendRequests()),
  acceptOrRejectFriendRequest: (acceptData) => dispatch(acceptOrRejectFriendRequest(acceptData)),
  getWellnessInterests: () => dispatch(getWellnessInterests()),
  fetchEditLocationApi: (data, deleteloc) => dispatch(editLocationApi(data, deleteloc)),
  fetchEditDepartmentApi: (data, deletedept) => dispatch(editDepartmentApi(data, deletedept)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(PeopleInactiveUsers));